/* eslint-disable react/jsx-no-comment-textnodes */
import { useCallback, useEffect, useState } from "react";
import { GET_MEMBERSHIP_INFO, GET_QRCODE } from "services/users";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import Layout from "./Layout";
import PopupInfo from "component/Popup/PopupInfo";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useHandleError from "hooks/useHandleError";
import { formatExpired, isValidEAN13 } from "utils/helper";

const Membership = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [isValidBarcode, setIsValidBarcode] = useState(false);
  const [afterEAN13, setAfterEAN13] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useHandleError();
  const [qrCode, setQRCode] = useState("");
  const [params, setParams] = useState({
    number: "-",
    point: "0",
    expired: "-",
  });

  const getUserInfo = useCallback(async () => {
    try {
      setAfterEAN13(false);
      const res = await GET_MEMBERSHIP_INFO();
      const data = res?.data;
      setParams({
        number: data?.card_number || "-",
        // number: "9900000032265",
        point: data?.point_balance
          ? data?.point_balance?.toLocaleString()
          : "0" || "0",
        expired: data?.point_expiration
          ? formatExpired(data?.point_expiration)
          : "-" || "-",
      });
      if (isValidEAN13(data?.card_number)) {
        // if (isValidEAN13("9900000032265")) {
        setIsValidBarcode(true);
      } else {
        setIsValidBarcode(false);
      }
      setAfterEAN13(true);
    } catch (error) {
      handleError(error);
      if (typeof error === "object") {
        console.error("get user membership detail", JSON.stringify(error));
      } else {
        console.error("get user membership detail", error);
      }
    } finally {
    }
  }, [dispatch, navigate]);

  const getQrCode = useCallback(async () => {
    try {
      const res = await GET_QRCODE();
      const data = res?.data;
      setQRCode(data);
    } catch (error) {
      handleError(error);
      if (typeof error === "object") {
        console.error("get user qrcode", JSON.stringify(error));
      } else {
        console.error("get user qrcode", error);
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    // Check if the user is returning from LINE LIFF
    const redirectUrl = localStorage.getItem("redirectAfterLineLiff");
    if (redirectUrl) {
      // Clear the stored URL and redirect to it
      localStorage.removeItem("redirectAfterLineLiff");
      window.location.href = redirectUrl;
    } else {
      getUserInfo();
      getQrCode();
    }
  }, []);

  return (
    <Layout showPanel={false} title="WAKO Membership Program">
      <div className="p-6 pt-4 pb-4 w-full grid gap-2 bg-[#E2D8CE]">
        <div className="flex items-center">
          <button
            className={`ryogothic text-white font-normal z-10 rounded-md py-1 px-4 cursor-pointer bg-[#737373] w-[90px] ml-0 text-sm my-auto`}
            onClick={() => setOpenPopup(true)}
          >
            連携解除
          </button>
          <div className="absolute left-0 ryogothic text-lg font-semibold text-primary-100 w-full text-center">
            会員証
          </div>
        </div>
        <div className="bg-white p-4 pb-6 mb-6">
          {params?.number && (
            <>
              {isValidBarcode ? (
                <Barcode
                  value={params?.number}
                  format="EAN13"
                  className="m-auto mb-6"
                />
              ) : afterEAN13 ? (
                <p className="text-red-500 font-semibold text-xs text-center pb-3">
                  不正な番号です。
                </p>
              ) : (
                <p className="pb-3"></p>
              )}
            </>
          )}
          {qrCode && (
            <QRCode
              size={56}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100px",
                margin: "auto",
              }}
              value={qrCode}
            />
          )}
        </div>
      </div>
      <div className="point-wrapper grid gap-5">
        <div className="grid gap-1">
          <div className="ryogothic text-2xs font-light leading-6 text-primary-100 w-full">
            保有ポイント
          </div>
          <hr className="bg-primary-100" />
        </div>
        <div className="grid m-auto">
          <div className="flex gap-1 items-baseline m-auto">
            <div className="ryogothic text-4xl font-semibold leading-6 text-primary-100 text-center">
              {params?.point}
            </div>
            <div className="ryogothic text-2xs font-light leading-6 text-primary-100 text-center">
              ポイント
            </div>
          </div>
          <div className="ryogothic text-xs !font-thin leading-6 text-primary-100 w-full">
            ポイント有効期限：{params?.expired}
          </div>
        </div>
        <div className="grid gap-1">
          <div className="ryogothic text-xs font-light leading-6 text-primary-100 w-full">
            ※保有ポイントは昨日時点のものです
          </div>
        </div>
        <div className="grid text-center">
          <div
            onClick={() =>
              window.open(
                "https://site.wako.co.jp/files/pdf/card_kiyaku.pdf",
                "_blank"
              )
            }
            className="ryogothic text-xs underline cursor-pointer font-light leading-6 text-primary-100 w-full"
          >
            利用規約について
          </div>
          <div
            onClick={() =>
              window.open("https://www.wako.co.jp/f/privacy_policy/", "_blank")
            }
            className="ryogothic text-xs underline cursor-pointer font-light leading-6 text-primary-100 w-full"
          >
            プライバシーポリシーについて
          </div>
        </div>
      </div>
      <PopupInfo open={openPopup} handleClose={() => setOpenPopup(false)} />
    </Layout>
  );
};

export default Membership;