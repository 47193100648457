/* eslint-disable react/jsx-no-comment-textnodes */
import { useState, React } from "react";
import info from "assets/images/info.svg";
import Layout from "./Layout";
import "./datepicker.css";
import { useNavigate } from "react-router-dom";
import { GET_VERIFY_CARD } from "services/users";
import { setUserInfo } from "store/user/userSlice";
import { setError } from "store/user/errorSlice";
import { useSelector, useDispatch } from "react-redux";
import PopupHelper from "component/Popup/PopupHelper";
import useHandleError from "hooks/useHandleError";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoadingLayer, setIsLoadingLayer] = useState(true);
  const [params, setParams] = useState({
    number: "",
    error: false,
  });
  const handleError = useHandleError(null, setParams, null, params, null);

  const Image = ({ src, alt, className, onClick }) => (
    <img
      loading="lazy"
      src={src}
      alt={alt}
      className={className}
      onClick={onClick}
    />
  );

  const handleChange = (type, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, '');
    if (type === "number" && sanitizedValue.length <= 13) {
      setParams((prevParams) => ({
        ...prevParams,
        [type]: sanitizedValue,
      }));
      setDisabledButton(false);
    } else if (type === "dob" && sanitizedValue.length <= 8) {
      setParams((prevParams) => ({
        ...prevParams,
        [type]: sanitizedValue,
      }));
    }
  };

  const handleRegister = () => {
    const body = {
      card_number: params?.number,
    };
    GET_VERIFY_CARD(body?.card_number)
      .then((res) => {
        const data = res?.data;
        if (data) {
          if (!data.birthdate_exist && !data.last_name_exist) {
            dispatch(
              setError({
                error: "birtdate_lastname_false",
              })
            );
            navigate("/error");
          } else {
            navigate("/card");
            dispatch(
              setUserInfo({
                ...userInfo,
                card_number: params?.number,
                birthdate_exist: data?.birthdate_exist,
                last_name_exist: data?.last_name_exist,
              })
            );
          }
        }
      })
      .catch((error) => {
        handleError(error);
        if (typeof error === "object") {
          console.error("card verify", JSON.stringify(error));
        } else {
          console.error("card verify", error);
        }
      });
  };

  const handleChangeRoute = (value) => {
    if (value === "/") setIsLoadingLayer(false);
    else setIsLoadingLayer(true);
  };

  return (
    <Layout isShowButtonHome={false} onChangeRoute={handleChangeRoute}>
      {isLoadingLayer ? (
        <></>
      ) : (
        <div className="grid gap-16 w-full">
          <div className="form-wrapper pb-0">
            <div className="pt-6 pb-3 w-full grid gap-1 text-center">
              <div className="ryogothic text-info leading-8 text-primary-100">
                &nbsp;お手持ちの和光カードの番号を入力してください
              </div>
              <div className="ryogothic text-info leading-8 text-primary-100">
                連携いただくとアプリを会員証として
              </div>
              <div className="ryogothic text-info leading-8 text-primary-100">
                ご使用いただけます。
              </div>
            </div>
            <div className="py-3 px-16 w-full grid gap-1">
              <div className="flex justify-between items-center">
                <div className="ryogothic text-2base font-light leading-6 text-primary-100 w-full text-left">
                  和光カード連携画面
                </div>
                <Image
                  src={info}
                  alt=""
                  className={"w-[20px] cursor-pointer"}
                  onClick={() => setOpenPopup(true)}
                />
              </div>
              <input
                type="number"
                inputMode="numeric"
                className={`ryogothic justify-center items-start text-left py-2 w-full text-2base font-light leading-5 whitespace-nowrap bg-white border-[#8E9291] border border-solid rounded-[4px] placeholder:text-zinc-400`}
                value={params?.number}
                placeholder="1234567890123"
                onChange={(e) => handleChange("number", e?.target?.value)}
                onKeyPress={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "." ||
                    e.key === "," ||
                    e.key === "-" ||
                    e.key === "=" ||
                    e.shiftKey
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
          <div>
            {params?.error && (
              <div className="w-full grid gap-1">
                <div className="ryogothic text-base text-center font-light leading-8 text-error-100 w-full">
                  カード番号が間違っています
                  <br />
                  もう一度入力してください。
                </div>
              </div>
            )}
            <div className="px-8 w-full grid gap-1">
              <button
                className={`button ${
                  disabledButton && "opacity-80 cursor-not-allowed"
                }`}
                disabled={disabledButton}
                style={{
                  background: disabledButton
                    ? "#a4a5a6"
                    : params?.number
                    ? "#4883F6"
                    : "#8e9291",
                  boxShadow: disabledButton && "none",
                  border: disabledButton && "solid 1px #a2a2a2",
                }}
                onClick={() => !disabledButton && handleRegister()}
              >
                次へ
              </button>
            </div>
          </div>
        </div>
      )}
      <PopupHelper open={openPopup} handleClose={() => setOpenPopup(false)} />
    </Layout>
  );
};

export default Home;
