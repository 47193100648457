import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import card from "assets/images/card.png";
import "./PopupInfo.css";

const PopupHelper = (props) => {
  const { open, handleClose } = props;
  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full md:max-w-mobile m-4 transform overflow-hidden rounded-2xlleft align-middle shadow-xl text-left transition-all mb-[30vh]">
                  <div className="flex flex-col px-6 py-8 rounded-2xl bg-[#D9D9D9] text-center">
                    <div className="ryogothic text-2xl font-light leading-6 text-primary-100 w-full text-center my-8">
                      和光カード番号入力案内
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      カード裏面の13桁の番号を
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-4">
                      入力してください。
                    </div>
                    <div className="w-64 m-auto">
                      <img src={card} alt="card" />
                      <div className="ryogothic text-xs text-left font-light leading-6 text-primary-100 w-full mb-2">
                        カード裏面の13桁の番号
                      </div>
                    </div>
                    <div className="flex justify-center gap-4">
                      <button
                        className={`button bg-[#737373] w-[150px] text-lg outline-none`}
                        onClick={handleClose}
                      >
                        閉じる
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupHelper;
