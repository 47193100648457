export const LOCALSTORAGE = {
  SUBKEY: "__sk__",
  USRRESULT: "__ur__",
  HASHPARAM: "__hp__",
  IDTOKEN: "__it__", // token for guardian & children
  TOKEN: "__token__", // token for guardian & children
  SEED: "__lsd__",
  ADMINJWT: "__aj__", // token for admin idear, school and teacher
  WEB3TOKEN: "__w3t__", // token from web3
  SUB: "__sub__",
};
