import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { DELETE_USER_INFO } from "services/users";
import { useNavigate } from "react-router-dom";
import "./PopupInfo.css";
import { useDispatch } from "react-redux";
import { setUserInfo } from "store/user/userSlice";
import { setError } from "store/user/errorSlice";
import close from "assets/images/close.png";
import { toast } from "react-hot-toast";

const PopupInfo = (props) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = () => {
    DELETE_USER_INFO()
      .then((res) => {
        const data = res?.data;
        if (data) {
          dispatch(setUserInfo(null));

          dispatch(
            setError({
              error: null,
            })
          );
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error(error.message || "Some error occurred");
        dispatch(
          setError({
            error: true,
          })
        );
      });
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full md:max-w-mobile m-4 transform overflow-hidden rounded-2xlleft align-middle shadow-xl text-left transition-all mb-[30vh]">
                  <div className="flex flex-col px-6 py-8 rounded-2xl bg-[#D9D9D9]">
                    <button
                      type="button"
                      className="absolute right-6 top-4 font-semibold text-[primary-100] cursor-pointer outline-none"
                      onClick={handleClose}
                    >
                      <img
                        src={close}
                        alt=""
                        className={
                          "w-[30px] cursor-pointer"
                        }
                      />
                    </button>

                    <div className="ryogothic text-2xl font-light leading-6 text-primary-100 w-full text-center mb-8">
                      連携解除
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      連携を解除すると和光アプリでカード
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-8">
                      情報を表示できなくなります。
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      連携解除後にご利用いただく場合は、
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      再度カード情報を入力いただき連携を
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      行っていただく必要があります。
                    </div>
                    <div className="flex justify-center gap-4">
                      <button
                        className={`button w-[150px] text-lg`}
                        onClick={handleClose}
                      >
                        キャンセル
                      </button>
                      <button
                        className={`button bg-[#EC4C4C] w-[150px] text-lg`}
                        onClick={handleDelete}
                      >
                        連携解除
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupInfo;
