/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import Layout from "./Layout";
import PopupExpired from "component/Popup/PopupExpired";
import { useSelector } from "react-redux";

const CardError = () => {
  const [error, setError] = useState("");
  const errorInfo = useSelector((state) => state.error.errorInfo);
  const [openPopup, setOpenPopup] = useState(
    error === "expired" ? true : false
  );
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (errorInfo.error === 401) {
      setErrorMessage("");
      setOpenPopup(true);
    } else if (errorInfo.error === 500) {
      setOpenPopup(false);
      setErrorMessage(
        "サーバーで一時的なエラーが発生しており<br />リクエストが完了できませんでした。<br />時間をおいて再度お試しください。"
      );
    } else if (errorInfo.error === "locked") {
      setOpenPopup(false);
      setError("locked");
      setErrorMessage(
        "認証に5回失敗したためロックされました<br /><br />カード連携を続ける場合は<br />15分後に再度入力ください"
      );
    } else if (errorInfo.error === "birtdate_lastname_false") {
      setOpenPopup(false);
      setErrorMessage(
        "カードの情報が<br />正常に取得できませんでした。<br />お手数をおかけしますが、<br />店舗スタッフへお尋ねください。"
      );
    } else if (errorInfo.error === "is_linked") {
      setOpenPopup(false);
      setErrorMessage(
        "このカードは別のアカウントに<br /> 登録されています。<br /> お手数をおかけしますが、<br /> 店舗スタッフへお尋ねください。"
      );
    }
  }, [errorInfo]);

  const handleBackLiff = () => {
    const lineAppUrl = `http://line.me/ti/p/${process.env.REACT_APP_LINE_BOT_ID}`;
    window.location.href = lineAppUrl;
  };
  return (
    <Layout isShowLoading={false}>
      {error !== "expired" && (
        <>
          <div className="p-6 pt-28 w-full grid gap-1">
            <div
              className="ryogothic text-base text-center font-light leading-8 text-error-100 w-full"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
          <div className="pt-12 px-8 w-full grid gap-1">
            <button
              className={`button ${
                error === "locked" ? "bg-black" : "bg-[#4883F6]"
              }`}
              onClick={handleBackLiff}
            >
              閉じる
            </button>
          </div>
        </>
      )}
      <PopupExpired open={openPopup} handleClose={() => setOpenPopup(false)} />
    </Layout>
  );
};

export default CardError;
