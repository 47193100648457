import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Membership from "./routes/Membership";
import CardError from "./routes/CardError";
import Card from "./routes/Card";
import TermUse from "./routes/TermUse";
import { Provider } from "react-redux";
import store from "./store";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import NotFound from "routes/NotFound";
import useHandleBackLiff from "hooks/useHandleBackLiff";

const RootApp = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const handleBackLiff = useHandleBackLiff();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("__token__")
  );

  const getUserInfo = useCallback(async () => {
    const code = window?.location?.href?.includes("code");
    try {
      const token = await getAccessTokenSilently({ detailedResponse: true });

      console.log("token", token);
      if (token.id_token) {
        localStorage.setItem("__token__", token.id_token);
        localStorage.setItem("after_login", true);
        Sentry.setUser({ id: token.id_token, username: token.id_token });
        setAccessToken(token.id_token);
      } else {
        if (!code) {
          await checkAuth();
        }
      }
    } catch (error) {
      if (!code) {
        await checkAuth();
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect]);
  
  const checkAuth = async () => {
    const isCheck = window?.location?.href?.includes("check");
    if (isCheck) {
      loginWithRedirect();
    } else {
      handleBackLiff();
    } 
  }

  useEffect(() => {
    const error = window?.location?.href?.includes("error_description");
    const handleAuth = async () => {
      if (error) {
        await checkAuth();
      } else if (!accessToken) {
        getUserInfo();
      }
    }  
    handleAuth()
  }, []);

  return (
    <Provider store={store}>
      {accessToken && (
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/card" element={<Card />} />
            <Route path="/term-of-use" element={<TermUse />} />
            <Route path="/error" element={<CardError />} />
            <Route path="/user-info" element={<Home />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      )}
    </Provider>
  );
};

const AppWithAuth = () => {
  const onRedirectCallback = async (appState, user) => {
    window.location.href = "/";
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}
      clientId={process.env.REACT_APP_OTP_CLIENT_ID}
      useCookiesForTransactions
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <RootApp />
    </Auth0Provider>
  );
};

export default AppWithAuth;
