import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setError } from "store/user/errorSlice";
import { useNavigate } from "react-router-dom";
import useHandleBackLiff from "./useHandleBackLiff";

const useHandleError = (
  errorSwitchMethod,
  setParams,
  setFailCounter,
  params,
  userInfo
) => {
  const handleBackLiff = useHandleBackLiff();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleError = useCallback(
    (error) => {
      let errorCode;
      if (error?.status === 400) {
        setParams((prevParams) => ({
          ...prevParams,
          name: "",
          dob: "",
          error: true,
          errMsg: error?.message,
        }));
        if (!isNaN(error?.message)) {
          if (error?.message === "5") {
            dispatch(setError({ error: "locked" }));
            navigate("/error");
          } else {
            setFailCounter(error?.message);
          }
        }

        if (params?.dob && userInfo?.last_name_exist) {
          errorSwitchMethod();
        }

        if (params?.name && userInfo?.birthdate_exist) {
          errorSwitchMethod();
        }

        if (error?.message === "account is locked") {
          dispatch(setError({ error: "locked" }));
          navigate("/error");
        } else if (error?.message === "Card is already linked") {
          dispatch(setError({ error: "is_linked" }));
          navigate("/error");
        }
      } else if (error?.status === 401) {
        handleBackLiff();
      } else if (error?.status >= 500) {
        errorCode = 500;
      }

      if (errorCode) {
        dispatch(setError({ error: errorCode }));
        navigate("/error");
      }
    },
    [
      dispatch,
      navigate,
      handleBackLiff,
      errorSwitchMethod,
      setParams,
      setFailCounter,
      params,
      userInfo,
    ]
  );

  return handleError;
};

export default useHandleError;