/* eslint-disable react/jsx-no-comment-textnodes */
import { useState, React, useEffect } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { UPDATE_USER_INFO } from "services/users";
import { setUserInfo } from "store/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  convertKanaF2H,
  convertKanaH2F,
} from "../../src/utils/convertKatakana";
import { toast } from "react-hot-toast";
import moment from "moment";
import useHandleError from "hooks/useHandleError";

const Card = () => {
  const [disabledButton, setDisabledButton] = useState(true);
  const [isBirthdate, setIsBirthdate] = useState(true);
  const [failCounter, setFailCounter] = useState(0);
  const userInfo = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params, setParams] = useState({
    name: null,
    dob: "",
    error: false,
    errMsg: "",
  });

  useEffect(() => {
    if (userInfo) {
      setIsBirthdate(userInfo.birthdate_exist);
    }
  }, [userInfo]);

  const handleChange = (type, value) => {
    setParams((prevParams) => {
      if (type === "dob") {
        if (validateDate(value)) {
          return {
            ...prevParams,
            [type]: value,
          };
        } else {
          return {
            ...prevParams,
            [type]: prevParams[type], // retain previous value if new value is invalid
          };
        }
      } else if (type === "name") {
        setDisabledButton(false);
        return {
          ...prevParams,
          [type]: value,
        };
      }
      return prevParams; // Default return to maintain state
    });
  };

  const handleSwitch = () => {
    setIsBirthdate(!isBirthdate);
  };

  const reset = () => {
    setParams({
      name: "",
      dob: "",
      error: false,
      errMsg: "",
    });
  };

  const handleRegister = () => {
    let birtdate;
    if (params?.dob) {
      birtdate = moment(params?.dob, "YYYYMMDD").format("YYYY-MM-DD");
    }
    const body = {
      card_number: userInfo?.card_number,
      birthdate: birtdate,
      last_name: params?.name,
    };
    UPDATE_USER_INFO(body)
      .then((res) => {
        const data = res?.data;
        if (data) {
          dispatch(
            setUserInfo({
              ...userInfo,
              first_name: data?.first_name,
              last_name: data?.last_name,
              card_number: data?.card_number,
              birthdate: data?.birthdate,
              point_balance: data?.point_balance,
            })
          );
          navigate("/membership");
        }
      })
      .catch((error) => {
        handleError(error);
        if (typeof error === "object") {
          console.error("update card link", JSON.stringify(error));
        } else {
          console.error("update card link", error);
        }
      });
  };

  const errorSwitchMethod = () => {
    // toast.error("Try 別の方法で連携する on button below!");
  };

  const handleConvertF2H = (e) => {
    setParams((prevParams) => ({
      ...prevParams,
      name: convertKanaF2H(e?.target?.value),
    }));
  };

  const validateDate = (value) => {
    toast.dismiss();
    if (value.length > 8) return false;
    const regex = /^\d*$/; // only number
    if (!regex.test(value)) {
      return false;
    }

    if (value.length >= 4) {
      const year = parseInt(value.slice(0, 4), 10);
      const getCurrentYear = moment().year();
      if (year < 1900 || year > getCurrentYear) {
        toast.error(`年は1900から${getCurrentYear}の間で入力してください`);
        return false;
      }
    }

    if (value.length >= 6) {
      const month = parseInt(value.slice(4, 6), 10);
      if (month < 1 || month > 12) {
        toast.error("月は01から12の間で入力してください");
        return false;
      }
    }

    if (value.length === 8) {
      const day = parseInt(value.slice(6, 8), 10);
      if (day < 1 || day > 31) {
        toast.error("日は01から31の間で入力してください");
        return false;
      }
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }

    return true;
  };

  const handleError = useHandleError(
    errorSwitchMethod,
    setParams,
    setFailCounter,
    params,
    userInfo
  );

  return (
    <Layout isShowButtonHome={false}>
      <div className="grid gap-2 w-full">
        {isBirthdate ? (
          <div className="ryogothic pt-6 text-base font-light text-primary-100 w-full text-center grid gap-2">
            <span>ご本人確認のため、</span>
            <span>ご登録の生年月日を入力してください。</span>
          </div>
        ) : (
          <div className="ryogothic pt-6 text-base font-light text-primary-100 w-full text-center grid gap-2">
            <span>ご本人確認のため、</span>
            <span>ご登録の姓(カタカナ)を入力してください。</span>
          </div>
        )}
        <div className="form-wrapper w-full px-12 pb-0">
          <div className="py-6 w-full grid gap-2">
            <div className="ryogothic text-2base font-light leading-6 text-primary-100 w-full text-left">
              和光カード番号
            </div>
            <div className="ryogothic text-2xl font-semibold leading-6 text-primary-100 w-full text-left">
              {userInfo?.card_number}
            </div>
          </div>
          {isBirthdate ? (
            <div className="w-full grid gap-1">
              <div className="grid">
                <div className="ryogothic text-2base font-light text-primary-100 w-full text-left">
                  生年月日
                </div>
              </div>
              <div className="grid gap-1">
                <input
                  type="number"
                  inputMode="numeric"
                  className={`ryogothic justify-center items-start text-left py-2 w-full text-2base font-light leading-5 whitespace-nowrap bg-white border-[#8E9291] border border-solid rounded-[4px] placeholder:text-zinc-400`}
                  value={params?.dob}
                  placeholder="19700123"
                  onChange={(e) => handleChange("dob", e?.target?.value)}
                  maxLength="8"
                  onKeyPress={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "." ||
                      e.key === "," ||
                      e.key === "-" ||
                      e.key === "=" ||
                      e.shiftKey
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <div className="ryogothic text-xs font-light text-primary-100 w-full text-left">
                  （例：1970年1月23日→ 19700123）
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full grid gap-1">
              <div className="ryogothic text-2base font-light leading-6 text-primary-100 w-full text-left">
                姓(カタカナ)
              </div>
              <input
                type="text"
                inputMode="text"
                className={`ryogothic justify-center items-start text-left py-2 w-full text-2base font-light leading-5 whitespace-nowrap bg-white border-[#8E9291] border border-solid rounded-[4px] placeholder:text-zinc-400`}
                value={params?.name}
                placeholder="ヤマダ"
                onChange={(e) => handleChange("name", e?.target?.value)}
                onBlur={handleConvertF2H}
              />
            </div>
          )}
        </div>
        <div>
          {params?.error && (
            <div className="px-3 w-full grid gap-1">
              {params?.errMsg === "Card is already linked" ? (
                <div className="ryogothic text-xs text-center font-light leading-6 text-error-100 w-full">
                  Card is already linked
                </div>
              ) : (
                <div className="ryogothic text-xs text-center font-light leading-6 text-error-100 w-full">
                  和光カード番号もしくは本人確認情報が間違っています <br />
                  あと{5 - failCounter} 回失敗するとログインできなくなります
                </div>
              )}
            </div>
          )}
          <div className="px-8 w-full grid gap-1">
            <button
              className={`button ${
                disabledButton && "opacity-80 cursor-not-allowed"
              }`}
              style={{
                background: disabledButton
                  ? "#a4a5a6"
                  : params?.dob || params?.name
                  ? "#4883F6"
                  : "#8e9291",
                boxShadow: disabledButton && "none",
                border: disabledButton && "solid 1px #a2a2a2",
              }}
              onClick={() => !disabledButton && handleRegister()}
            >
              カード連携
            </button>
          </div>
          <div className="p-4 pb-4 w-full grid gap-1 justify-center">
            <button
              className={`button bg-black w-[152px]`}
              onClick={() =>
                params?.dob || params?.name ? reset() : navigate("/")
              }
            >
              ＜ 戻る
            </button>
          </div>
          {failCounter >= 1 &&
            ((isBirthdate && userInfo?.last_name_exist) ||
              (!isBirthdate && userInfo?.birthdate_exist)) && (
              <div className="py-4 px-8 w-full grid gap-1">
                <div
                  className="inter text-lg underline font-light leading-6 text-primary-100 w-full text-center cursor-pointer hover:font-semibold"
                  onClick={handleSwitch}
                >
                  別の方法で連携する
                </div>
              </div>
            )}
        </div>
      </div>
    </Layout>
  );
};

export default Card;
